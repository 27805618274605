import "../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-974f8ebad1/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.12-320e58033d-6f955c79b1.zip/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2FuiPrimitives%2Fbase%2Flayers.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WWUW7DIAxA%2F3eKnSAX6E%2B1apoiTdPUGzjETdEIRg5Jm51%2BStatUBVDPzGPZ2JcytbAjPxssAM173FAv3na%2FsY4GgXEHifk25lg2TL7HgcbGPB%2FMOpP1r32esIhCNaKbDDcUe9o0D5gGkPq64PaIDSg8prsNXAgNV5HNCGfOJKE2avkvirH5BKLPIMd9JI2La0MzDR6wS0SgaIajsDY5k0iGAoddPjGukQpo6GUiXwZqYDbwvQyGn2TNtJB5rFQBsxguxKfSEaHqJiMgcbgDrhNNFZ8miUrwhSk1OjmArMEhsLv2rZ4LhBK4L3Gqz32DzRfGr%2FXVoXyPB7KGyqpQ5KKikDS3REXQESj9hodclf2o8qwsbYplgrkqvSzo47BHcUWzVE3qsrj2b8sfwtl0hx%2FT19bo614GTywYE3QY6tBEgrAVVDpHuRLKosFsmHq8qokFO5Kyb2do25U%2BeqXsatWW48MaolIxiw2em20T7ToZbJa3h4HQyeZOpjUzflHOFr38zqh9TfvnPj9cgnRefMDD5f2ONYJAAA%3D%22%7D"
import "../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-974f8ebad1/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.12-320e58033d-6f955c79b1.zip/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2FuiPrimitives%2Fcolor%2Fcolors.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42R0WqDMBSG732KcyN0cITERJvYm77JiNa2W9Nms9tgjL77iNbV6GF6ISj5yP%2F%2Fn1trvusGSnOtN9H28QE%2FEUDROPfRvgEkyTPfy9K%2BW1bA8WpXDIHF%2FnnajADeASljCFrHCIJPoXQIqRghTaeQ6CDhISFiBEnEyaAPRWQdIf%2Bpk3cIF75O5utkU2gdJHFGRKk5OToAFEGYJfrKJfqqXh%2BCXns3xKbdg%2FGDECSRVs8L3C8ReJgXeOyHpQi5v0cTYS89JBDy3HskoNf7Mt5qRtBE2mnEUP%2FDdkwmEHSbRZg%2BDxhfOmvHj6lLqFGp6UUuMKQJh28h8Ve4NNXp0LjPyy6pnHVNAV%2BmWQ3c3znykLWHt%2Bj2C5uZ6hMQBAAA%22%7D"
import "../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-974f8ebad1/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.12-320e58033d-6f955c79b1.zip/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2FuiPrimitives%2Fbase%2Ffocus.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2BVXbW%2BbQAz%2Bzq%2Bw%2BqGCCaqkTbvpIlX5H9M0HcGM2%2BgduzMJ2cR%2Fn8ZrYA1T22PSEiEh8Nnm%2FNg89m1SfkANsdrm5iZUxdrZDCTc4NrZmDzLlCYDUhEYTHFLSrus0gl2wogwRQ9%2BOgDPWJcjl6qoNNk%2BQY0u9yHMiZT0weRPT1wffBAyy8kHwoK4Ru43n%2FThI%2FFQyAiLT0wqckMVHTxvuA14hJvPy9Xibvn1212lZe1DdYQAKqdUSAwMHVJkEHGTYLQeLO1FRAmD26wYylUcGyQGQbcSqiIwCY%2FUnoGQBgkW1XWbFbDj2g2CNpql3wniVZh%2BT7943tD7VqVKs5HZYmz2oTYrHThGaqZsnC1iqwYxlnDjPs5Vzedccw%2FDUBmPCbV%2FvP4b2u71foRMrd%2FAs1WSUBKDq6v1GeEFkCkjSCjJgIdGpTlhLa%2B2wWDRaglJqAPcoSTDQCqJLd4vIG%2FrpPwPyHgy1aeTPZVuOwl%2FdcrL6j4bNV8KbvMS9OXU30MX6HUdekvU1xWyI3oe0vJf4ZkCaBoiWyC9GqZB2%2BlFpwj7T9KeIu4%2BD2VF4%2BMZmpt6tOqy9N5yffvwbsLjFL30W2qKoEdEY8pJ7JoIfwSVCwbLtlXZi6F21HMBWCQAHxpKma21jai6KdBhW7eH1dHe%2B5%2F%2BaA67P3r%2B74Cc7Tzy7HBYE9Bq%2FjG6fNOZ3PqwdwklZLvfn2j3Ly%2BhNzX60il%2FARgLRbADEgAA%22%7D"
export var focusBackground = 'var(--_14031jk1)';
export var focusBox = '_14031jk3';
export var focusForeground = 'var(--_14031jk0)';
export var focusMain = '_14031jk6';
export var focusMainTarget = '_14031jk5';
export var focusShadow = 'var(--_14031jk2)';
export var focusWithZIndex = '_14031jk7';
export var focusWrapper = '_14031jk4';